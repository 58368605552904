// src/CookieConsent.js

import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import {initializeAnalytics, removeAnalyticsCookies} from "./initializeAnalytics";
const CookieConsent = () => {
    const [open, setOpen] = useState(true);


    const handleAccept = () => {
        initializeAnalytics();
        setOpen(false);
        localStorage.setItem('cookieConsent', 'accepted');
    };

    const handleDecline = () => {
        removeAnalyticsCookies()
        setOpen(false);
        localStorage.setItem('cookieConsent', 'declined');
        window.location.reload()
    };

    return (
        <Snackbar
            open={open}
            className={"text-center bg-white"}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message="We use cookies to improve your experience. Do you accept?"
            action={
                <>
                    <Button color="secondary" size="small" onClick={handleDecline}>
                        Decline
                    </Button>
                    <Button color={"primary"} size="small" onClick={handleAccept}>
                        Accept
                    </Button>
                </>
            }
            ContentProps={{
                style: {
                    backgroundColor: 'white', // Set your desired color here
                    color: 'black' // Ensuring text color is visible on white background
                }
            }}
        />
    );
};

export default CookieConsent;
