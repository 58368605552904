import React, { useState} from 'react';
import { Container, Navbar, Nav} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setAuth, setUserName, setUserID} from "../../redux/authSlice";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {

    Person,
    SupervisedUserCircleRounded,
    FindInPage,

    DynamicForm,

    FolderShared,
    AccountCircle,
    Menu as MenuIcon,
    Close,
    Info, Lock
} from "@mui/icons-material";
import { Menu, MenuItem, IconButton, Box,Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';


function Header(props) {
    // using redux to get the auth value
    const dispatch = useDispatch()
    const auth = useSelector((state:RootState) => state.auth.value)
    const user = useSelector((state:RootState) => state.auth.username)
    // const userId = useSelector((state:RootState) => state.auth.userId)

    const logout = async () => {
        await axios.post('logout',{},{withCredentials:true})
        // setting auth token to nothing
        axios.defaults.headers.common['Authorization'] = "";
        // setAuth(false)
        // setUserName('')
        dispatch(setAuth(false))

    }
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setExpanded(false)
    };
    const handleAccounClose = () => {
        setAnchorEl(null);
    };
    const [expanded, setExpanded] = useState(false);


    const CustomToggle = React.forwardRef(({ onClick, expanded }, ref) => (
        <IconButton
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="navbar-toggler"
            style={{ outline: 'none' }} // Remove the focus outline
        >
            {expanded ? <Close /> : <MenuIcon />}
        </IconButton>
    ));

    return (
        <Navbar
            className={'bg-light custom-border'}
            expand="xxl"
            id="nav"
            expanded={expanded}
        >
            <Container fluid className={"align-items-center d-flex"}>
                <Navbar.Brand as={Link} onClick={() => setExpanded(false)} to="/" className={"align-items-center d-flex text-primary text-outline"}>
                    {/*<h6 className={"text-primary border p-1 rounded border-primary"}>LODI: LAB OF DATA INSIGHTS</h6>*/}
                    <img
                        // src={"https://d3deh4arfmquak.cloudfront.net/lodi-logo-9.png"}
                        // src={"https://d3deh4arfmquak.cloudfront.net/LODI-HEADER-02.png"}
                        // src={"https://d3deh4arfmquak.cloudfront.net/LODI-0101.png"}
                        src={"https://d3deh4arfmquak.cloudfront.net/LODI-MENU1.png"}
                        alt="Laboratory of Data Insights"
                        style={{ height: "35px", width: '140px' }}
                    />
                </Navbar.Brand>


                <Navbar.Toggle as={CustomToggle} aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} expanded={expanded} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto e-text-right mb-1">
                            {auth && <Nav.Item className="my-1">
                                    <Link onClick={() => setExpanded(false)} to="/myproducts" className="text-decoration-none  px-2 text-primary ">
                                        {/*<FontAwesomeIcon className={"text-white"}  icon={faCompass}/> COMPASS*/}
                                        <FolderShared/> My Products
                                    </Link>
                                </Nav.Item> }
                            <Nav.Item className="my-1 ">
                                <Link onClick={() => setExpanded(false)} to="/products" className="text-decoration-none  px-2 text-primary">
                                    <DynamicForm/> Software
                                    {/*<FontAwesomeIcon className="text-white" icon={faCode}/>*/}
                                </Link>
                            </Nav.Item>
                            <Nav.Item className="my-1 ">
                                <Link onClick={() => setExpanded(false)} to="/services" className="text-decoration-none  px-2 text-primary">
                                    <SupervisedUserCircleRounded/> Services
                                    {/*<FontAwesomeIcon className="text-white" icon={faCode}/>*/}
                                </Link>
                            </Nav.Item>

                            <Nav.Item className="my-1">
                                <a href="https://research.labofdata.com" target={'_blank'} rel="noopener noreferrer" className="text-decoration-none  px-2 text-primary">
                                    <FindInPage/> Research
                                </a>
                            </Nav.Item>
                        <Nav.Item className="my-1 ">
                            <Link onClick={() => setExpanded(false)} to="/privacy" className="text-decoration-none  px-2 text-primary">
                                <Lock/> Privacy
                                {/*<FontAwesomeIcon className="text-white" icon={faCode}/>*/}
                            </Link>
                        </Nav.Item>
                        <Nav.Item className="my-1 ">
                            <Link onClick={() => setExpanded(false)} to="/about" className="text-decoration-none  px-2 text-primary">
                                <Info/> About
                                {/*<FontAwesomeIcon className="text-white" icon={faCode}/>*/}
                            </Link>
                        </Nav.Item>



                            {!auth ? (
                                <>
                                    {/*<Nav.Item className="my-1 mx-4">*/}
                                    {/*    <Link to="/login" className="text-decoration-none custom-nav-item px-2 btn-info border-success border pt-1 pb-2 text-primary shadow-sm">*/}
                                    {/*        <Person/>Log in*/}
                                    {/*    </Link>*/}
                                    {/*</Nav.Item>*/}
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'center', color: 'primary.main' }}>

                                    <Button
                                        component={Link}
                                        to="/login"
                                        variant="outlined"
                                        startIcon={<Person />}
                                        size={"small"}
                                        sx={{color:"#082868"}}
                                        className={" border-success"}
                                        onClick={() => setExpanded(false)}
                                    >
                                        Sign in
                                    </Button>
                                    </Box>

                                </>
                            ):(
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'center', color: 'primary.main' }}>
                                    {/* User Display and Dropdown Trigger */}
                                    <Button
                                        onClick={handleClick}
                                        className=" text-primary text-sm border-primary"
                                        style={{ fontSize: "12px" }}
                                        variant={"outlined"}
                                        startIcon={<AccountCircle />}
                                    >
                                        {user}
                                    </Button>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}

                                        onClose={handleAccounClose}
                                    >
                                        <MenuItem onClick={handleClose} component={Link} to="/contact" sx={{color:"#082868"}} >
                                            <MailIcon fontSize="small" />&nbsp; Contact
                                        </MenuItem>
                                        <MenuItem onClick={handleClose} component={Link} to="/profile" sx={{color:"#082868"}} >
                                            <AccountBoxIcon fontSize="small" />&nbsp; Profile
                                        </MenuItem>
                                        <MenuItem onClick={() => { handleClose(); logout(); }} component={Link} to="/login" sx={{color:"#082868"}} >
                                            <ExitToAppIcon fontSize="small" />&nbsp; Logout
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            )}

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
