import {createSlice} from "@reduxjs/toolkit";

// redux reducers make it able for components to dynamically set values between eachother

const initialState = {
    value: false,
    username:"",
    userId:0,
}
export const authSlice = createSlice({
    name:"auth",
    initialState,
    reducers: {
        setAuth: (state,action) => {
            state.value = action.payload;
        },
        setUserName: (state,action) => {
            state.username = action.payload;
        },
        setUserID :(state,action) => {
            state.userId = action.payload;
        },
        setEmail :(state,action) => {
            state.email = action.payload;
        },
    }
})


//
export const {setAuth,setUserName,setUserID,setEmail} = authSlice.actions


// used within store.js
export default authSlice.reducer

