// import logo from './logo.svg';
import './App.css';
import './bootstrap.min.css'
import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/nav/Header';
import Footer from './components/nav/Footer';
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuth, setEmail, setUserID, setUserName } from './redux/authSlice';
import CookieConsent from '../src/components/user/CookieConsent'
import {initializeAnalytics} from "./components/user/initializeAnalytics";

const Login = lazy(() => import('./components/user/login/Login'));
const Register = lazy(() => import('./components/user/Register'));
const Home = lazy(() => import('./screens/Home'));
const Forgot = lazy(() => import('./components/user/Forgot').then(module => ({ default: module.Forgot })));
const Reset = lazy(() => import('./components/user/Reset').then(module => ({ default: module.Reset })));
const EmailVerification = lazy(() => import('./components/user/EmailVerification'));
const Products = lazy(() => import('./components/software/Products'));
const MyProducts = lazy(() => import('./components/software/MyProducts'));
const TrailBlazer = lazy(() => import('./apps/TrailBlazer'));
const VizRxDemo = lazy(() => import('./apps/VizRxDemo'));
const TrailBlazerDocs = lazy(() => import('./components/app-specific/trailblazer/TrailBlazerDocs'));
const Product = lazy(() => import('./components/software/Product'));
const Profile = lazy(() => import('./components/user/Profile'));
const Contact = lazy(() => import('./components/user/Contact'));
const VizKPIDemo = lazy(() => import('./apps/VizKPIDemo'));
const LockedIn = lazy(() => import('./apps/LockedIn'));
const Services = lazy(() => import('./screens/Services'));
const About = lazy(() => import('./screens/About'));
const TermsServices = lazy(() => import('./screens/TermsServices'));
const PageNotFound = lazy(() => import('./screens/PageNotFound'));
const LockedInDocs = lazy(() => import('./components/app-specific/lockedin/LockedInDocs'));
const Privacy = lazy(()=> import('./screens/Privacy'))

function App() {
    const dispatch = useDispatch()
    const [cookieConsent, setCookieConsent] = useState(localStorage.getItem('cookieConsent'));


    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get('user',{withCredentials:true});
                // console.log(data)
                dispatch(setUserName(`${data.first_name} ${data.last_name}`))
                dispatch(setUserID(data.id))
                dispatch(setEmail(data.email))
                dispatch(setAuth(true))
            } catch (err) {
                // console.log(err)

                dispatch(setUserName(""))
                dispatch(setUserID(0))
                dispatch(setEmail(""))
                dispatch(setAuth(false))
            }
            // console.log("user auth called")
        })();
    }, [dispatch]);


    useEffect(() => {
        if (cookieConsent === 'accepted') {
            initializeAnalytics();
        }
    }, [cookieConsent]);
  return (

          <BrowserRouter>
              <Header/>
              <Suspense fallback={<div>Loading...</div>}>
              <main className=" main-content bg-white " >

              <Routes>
                  <Route path="/" element={
                      <Home/>
                  } />
                  {/* USER RELATED URLS*/}
                  <Route path="/login" element={
                      <Login/>
                  } />
                  <Route path="/register" element={
                      <Register/>
                  } />
                  <Route path="/forgot" element={
                      <Forgot/>
                  } />
                  <Route path="/reset/:token" element={
                      <Reset/>
                  } />
                  <Route path="/validate" element={<EmailVerification />} />
                  <Route path="/contact" element={<Contact/>} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/about" element={<About />} />
                    <Route path={"/privacy"} element={<Privacy/>} />


                    {/*Software Paths*/}

                  <Route path="/products" element={<Products />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/product/:id" element={
                      <Product/>
                  } />
                  <Route path="/myproducts" element={<MyProducts />} />


                  {/*Application Paths*/}
                  <Route path="/TrailBlazer" element={<TrailBlazer />} />
                  <Route path="/TrailBlazer/docs" element={<TrailBlazerDocs />} />
                  <Route path="/LockedIn/docs" element={<LockedInDocs />} />

                  <Route path="/VizRx/demo" element={<VizRxDemo />} />
                  {/*<Route path="/dashio" element={<Dashio />} />*/}
                  {/*<Route path="/mossyoak" element={<MossyOak />} />*/}


                  {/*<Route path="/VizKPI" element={<VizKPI/>}/>*/}
                  <Route path="/VizKPI/Demo" element={<VizKPIDemo/>}/>
                  <Route path="/LockedIn" element={<LockedIn/>}/>
                  <Route path="/terms" element={<TermsServices/>}/>
                  <Route path="*" element={<PageNotFound/>}/>
                  {/*<Route path="/TrendBlazer" element={<TrendBlazer/>}/>*/}



              </Routes>
              </main>
              </Suspense>
              {!cookieConsent && <CookieConsent />}
              <Footer/>
          </BrowserRouter>

  );
}

export default App;
