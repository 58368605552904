
// const tag_id = "305EHF8NJQ" // dev
const tag_id = "V895WD6KF3" // prod
export const initializeAnalytics = () => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-`+tag_id;
    script.async = true;
    document.head.appendChild(script);
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-'+tag_id);
    };
};
export const removeAnalyticsCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
        const cookieName = cookie.split("=")[0].trim();
        // console.log(cookieName)
        if (cookieName.startsWith("_ga")) {
            // Remove the cookie by setting its expiration date to the past
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname};`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${window.location.hostname};`;
        }
    });
};