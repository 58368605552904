import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// axios interceptors for custom functionality
import './interceptors/axios';

// redux imports
import './redux/store'
import {Provider} from "react-redux"
import {store} from "./redux/store";


// syncfusion liscense
import {registerLicense} from "@syncfusion/ej2-base";
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

      <Provider store={store}>
    <App />
      </Provider>

);


// <React.StrictMode>
// note react strict mode causes rerenders - but is good for development, just remove if its too much, a
// before deploying to production :p



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
