import { Container, Row, Col, Button } from 'react-bootstrap';
function Footer() {

    return (
        <div className={ 'bg-light footer'} style={{zIndex:99}}>
            <Container id={"foot"} >
                <Row>
                    <Col className="text-center text-primary" >
                        <a href="https://www.roadmap-tech.com" className={"text-decoration-none  "} target="_blank"  ><h5 className={"text-primary m-0"}> RoadMap Technologies &copy; 2024</h5></a>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default Footer;